import React from 'react';
import useStyles from './navbar.style';
import { Link } from 'gatsby';
import NavbarLogo from './navbar-logo';

const Navbar = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link className={classes.navbarLogoLink} to="/">
        <NavbarLogo className={classes.navbarLogo} />
      </Link>
      <nav className={classes.nav}>
        <ul className={classes.ul}>
          <li>
            <Link to="/">Strona główna</Link>
          </li>
          <li>
            <Link to="/oferta">Oferta</Link>
          </li>
          <li>
            <Link to="/kontakt">Kontakt</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
