import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const NavbarLogo = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "nav-logo-main.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      className={className}
      fluid={data.placeholderImage.childImageSharp.fluid}
      loading="eager"
      fadeIn={false}
      alt="Toms-Bud Logo"
    />
  );
};

export default NavbarLogo;
