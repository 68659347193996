import React from 'react';

const Map = () => {
  return (
    <>
      <iframe
        height="100%"
        width="100%"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        style={{ marginBottom: 4 }}
        src="https://www.openstreetmap.org/export/embed.html?bbox=19.54403936862946%2C51.755369120691896%2C19.551120400428776%2C51.758331073639745&amp;layer=mapnik&amp;marker=51.75685012145088%2C19.547579884529114"
      ></iframe>
      <br />
      <small>
        <a href="https://www.openstreetmap.org/?mlat=51.75685&amp;mlon=19.54758#map=18/51.75685/19.54758">
          Wyświetl większą mapę
        </a>
      </small>
    </>
  );
};

export default Map;
