/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": ["GeneralContractor", "HVACBusiness", "Plumber"],
          "name": "Toms-Bud Usługi instalacyjno-hydrauliczne",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Lermontowa 4 m 48",
            "addressLocality": "Łódź",
            "addressRegion": "łódzkie",
            "postalCode": "92-512",
            "addressCountry": "PL"
          },
          "email": "biuro@toms-bud.pl",
          "keywords": "kotły gazowe, pompy ciepła, centralne ogrzewanie, instalacje gazowe, instalacje wod-kan, hydraulika, łódź",
          "image": "https://toms-bud.pl/images/nav-logo-main.png",
          "telephone": "+48 608 026 287",
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 51.756684,
            "longitude": 19.547853
          },
          "url": "https://toms-bud.pl/"
        }
      `}</script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
