import { makeStyles } from '@material-ui/styles';
import { BLUE, BLACK, ORANGE } from '../../constants/colors';
import { TABLET_MAX, LAPTOP_MAX } from '../../constants/breakpoints';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    marginTop: '10vh',
    height: 300,
    width: '100vw',
    backgroundColor: BLUE,
    color: 'white',

    padding: '0 10%',
    display: 'grid',
    gridTemplateColumns: '2fr 3fr 1fr',
    [LAPTOP_MAX]: {
      padding: '0 1%',
    },
    [TABLET_MAX]: {
      padding: '0 5%',
      gridTemplateColumns: '1fr',
      height: 'auto',
    },
  },
  copyright: {
    position: 'absolute',
    bottom: 0,
    height: '3em',
    width: '100%',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    fontSize: '0.8em',
    color: 'white',
    backgroundColor: BLACK,
  },

  column: {
    padding: '2em',
    [TABLET_MAX]: {
      padding: '1.5em 0.5em 0',
      '&:nth-of-type(3)': {
        marginBottom: '3em',
      },
    },
  },
  header: {
    position: 'relative',
    fontSize: '1.2em',
    fontWeight: 600,

    '&::before': {
      content: "' '",
      position: 'absolute',
      bottom: '-12px',
      width: '36px',
      height: '4px',
      backgroundColor: ORANGE,
    },
  },
  text: {
    flex: 1,
    fontSize: '0.9em',
    marginTop: '1.2rem',
    color: 'rgba(255,255,255, 0.8)',
    letterSpacing: '0.1px',
  },
  contact: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  listItem: {
    fontSize: '0.95em',
    position: 'relative',
    listStyle: 'none',

    '&::before': {
      content: "' '",
      position: 'absolute',
      top: 5,
      left: -18,
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: ORANGE,
    },
  },
  mapContainer: {
    flex: 1,
    height: 160,
    marginTop: -30,
    minWidth: 280,
    [TABLET_MAX]: {
      minWidth: '100%',
      marginTop: 0,
      marginBottom: 32,
    },
  },
  navigationList: {
    marginTop: '1.2rem',
  },
});

export default useStyles;
