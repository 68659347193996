import { makeStyles } from '@material-ui/styles';
import { BLACK } from '../../constants/colors';
import { NAVBAR_HEIGHT } from '../../constants/ui';
import { PHONE_MAX } from '../../constants/breakpoints';
import tomsBudIcon from '../../images/toms-bud-icon.png';

const useStyles = makeStyles({
  root: {
    backgroundColor: BLACK,
    color: 'white',
    width: '80vw',
    height: NAVBAR_HEIGHT,
    top: '2em',
    position: 'fixed',
    left: '10vw',
    zIndex: 5,
    display: 'flex',
    flexDirection: 'row',
    [PHONE_MAX]: {
      width: '90vw',
      left: '5vw',
      top: '1em',
      height: '2.2em',
      overflow: 'hidden',
    },
  },
  navbarLogoLink: {
    position: 'relative',
    display: 'flex',
    width: '17em',
    height: `calc(${NAVBAR_HEIGHT} + 10.5px)`,
    top: -11,
    left: -37,
    [PHONE_MAX]: {
      display: 'none',
    },
  },
  navbarLogo: {
    flex: 1,
  },
  nav: {
    marginLeft: 'auto',
    marginRight: '0.5em',
    [PHONE_MAX]: {
      marginLeft: 0,
      width: '100%',
    },
  },
  ul: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    listStyle: 'none',
    marginLeft: 0,
    '& > li': {
      margin: '0 2px',
      padding: '0 8px',
      display: 'flex',
      alignItems: 'center',
    },
    '& > li > a': {
      fontSize: '0.8em',
      color: 'white',
      textDecoration: 'none',
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
    },
    [PHONE_MAX]: {
      width: '100%',
      justifyContent: 'space-around',
      paddingLeft: '1.2em',
      '& > :first-child': {
        marginLeft: -40,
      },
      '& > :first-child > a': {
        paddingLeft: 40,
      },
      '& > :first-child::before': {
        position: 'absolute',
        left: '0.6em',
        height: '1.4em',
        width: '1.2em',
        background: `url('${tomsBudIcon}') center/cover`,
        content: "' '",
      },
    },
  },
});

export default useStyles;
