export const PHONE_BREAKPOINT = 480;
export const TABLET_BREAKPOINT = 1080;

/**
 * Get media breakpoint
 * @param {'min' | 'max'} minOrMax
 * @param {number} pixels
 * @returns {string}
 */
export const getBreakpoint = (minOrMax, pixels) => `@media (${minOrMax}-width: ${pixels}px)`;

export const PHONE_MAX = getBreakpoint('max', PHONE_BREAKPOINT);
export const TABLET_MAX = getBreakpoint('max', TABLET_BREAKPOINT);
export const LAPTOP_MAX = getBreakpoint('max', 1366);
