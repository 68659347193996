import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header/header';
import './layout.css';
import Footer from './Footer/footer';

const Layout = ({ rootClassName, children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <Header />
      <main className={rootClassName}>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
