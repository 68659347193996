import PropTypes from 'prop-types';
import React from 'react';
import Navbar from '../Navbar/navbar';
import useStyles from './header.style';
import HeaderImage from './header-image';
import HeaderLogo from './header-logo';

const Header = () => {
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <Navbar />
      <HeaderImage className={classes.headerImage} />
      <HeaderLogo className={classes.headerLogo} />
    </header>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
