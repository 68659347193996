import React from 'react';
import useStyles from './footer.style';
import { Link } from 'gatsby';
import Map from '../Shared/Map/map';

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.root}>
      <div className={classes.column}>
        <h3 className={classes.header}>O nas</h3>
        <p className={classes.text}>
          Jesteśmy firmą świadczącą usługi instalacyjne od 2008 roku. W tym czasie wykonaliśmy wiele
          prac instalacyjnych na terenie Łodzi i województwa.
          <br />
          Posiadamy aktualne uprawnienia energetyczne i gazowe. Współpracujemy z firmą Junkers-Bosch
          i posiadamy autoryzację na montaż urządzeń.
          <br />
          Prowadzimy również próby szczelności.
        </p>
      </div>
      <div className={classes.column}>
        <h3 className={classes.header}>Skontaktuj się z nami</h3>
        <div className={classes.contact}>
          <div className={classes.text}>
            <ul>
              <li className={classes.listItem}>Adres: Lermontowa 4 m 48</li>
              <li className={classes.listItem}>Telefon: +48 608 026 287</li>
              <li className={classes.listItem}>Email: biuro@toms-bud.pl</li>
              <li className={classes.listItem}>NIP: 728-126-58-10</li>
            </ul>
          </div>
          <div className={classes.mapContainer}>
            <Map />
          </div>
        </div>
      </div>
      <div className={classes.column}>
        <h3 className={classes.header}>Nawigacja</h3>
        <ul className={classes.navigationList}>
          <li className={classes.listItem}>
            <Link to="/">Strona główna</Link>
          </li>
          <li className={classes.listItem}>
            <Link to="/oferta">Oferta</Link>
          </li>
          <li className={classes.listItem}>
            <Link to="/kontakt">Kontakt</Link>
          </li>
        </ul>
      </div>
      <div className={classes.copyright}>©Toms-Bud {new Date().getFullYear()}</div>
    </footer>
  );
};

export default Footer;
