import { makeStyles } from '@material-ui/styles';
import { PHONE_MAX, TABLET_MAX } from '../../constants/breakpoints';

const useStyles = makeStyles({
  header: {
    height: 500,
    overflow: 'visible',
    [TABLET_MAX]: {
      height: 300,
    },
    [PHONE_MAX]: {
      height: 250,
    },
  },
  headerImage: {
    position: 'absolute',
    top: 0,
    height: 600,
    width: '100vw',
    zIndex: '-1',
    [PHONE_MAX]: {
      height: 300,
    },
  },
  headerLogo: {
    width: '50vw',
    margin: '0 auto',
    position: 'absolute',
    top: -450,
    [PHONE_MAX]: {
      width: '90vw',
      top: -200,
    },
  },
});

export default useStyles;
